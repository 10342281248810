<template>
  <div class="dashboard">
    <!-- Main -->
    <div>
      <v-container style="max-width: 1200px">
        <h1 style="margin-bottom: 10px">Hallo {{ user.prename }}!</h1>
        <p>Schön dich heute zu sehen. Es stehen {{ totalCases }} neue Fälle zur Verfügung.</p>
        <div style="width: 100%; text-align: center; margin-top: 40px" v-if="user.accessRoles.cases.enter">
          <v-btn class="success white--text" @click="startWork('regular')" width="50%" height="50px" style="text-transform: none">Jetzt loslegen!</v-btn>
        </div>
        <div style="width: 100%; text-align: center; margin-top: 40px" v-if="user.accessRoles.cases.enter">
          <v-btn class="text--text" @click="startWork('empty')" width="50%" height="50px" style="text-transform: none">Fälle extern hinzufügen</v-btn>
        </div>
        <div style="width: 100%; text-align: center; margin-top: 40px" v-if="user.accessRoles.cases.export">
          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="3">
              <b>Start</b>
              <v-text-field v-model="from" type="datetime-local"></v-text-field>
            </v-col>
            <v-col cols="3">
              <b>Ende</b>
              <v-text-field v-model="to" type="datetime-local"></v-text-field>
            </v-col>
          </v-row>
          <v-btn class="text--text" @click="downloadFileWithTime" width="50%" height="50px" style="text-transform: none; margin-bottom: 20px" v-if="user.accessRoles.cases.export">Download CSV Start-Ende</v-btn><br>
          <v-btn class="text--text" @click="downloadFile" width="50%" height="50px" style="text-transform: none; margin-bottom: 20px" v-if="user.accessRoles.cases.export">Download CSV</v-btn><br>
        </div>
      </v-container>
    </div>

    <!-- Statistics -->
    <div v-if="user.accessRoles.cases.userStats">
      <v-container style="max-width: 1200px">
        <h1 style="margin-bottom: 10px">Deine Statistik</h1>
        <div style="width: 80%; height: 500px; margin: 40px auto 0; background-color: rgba(255,255,255,0.8)">
          <v-card class="unvergessen-shadow">
            <div>
              <h3 style="text-align: center" class="mt-6">Bearbeitete Fälle der Letzten 14 Tage</h3>
              <div style="padding: 10px">
                <apex-chart
                  type="line"
                  height="350"
                  ref="realtimeChart"
                  :options="chartOptions"
                  :series="series"
                ></apex-chart>
              </div>
            </div>
          </v-card>
        </div>
      </v-container>
    </div>

    <!-- Admin Statistics -->
    <div v-if="user.accessRoles.cases.adminStats">
      <v-container style="max-width: 1200px">
        <h1 style="margin-bottom: 10px">Nutzerstatistik</h1>
        <div style="width: 80%; height: 500px; margin: 40px auto 0; background-color: rgba(255,255,255,0.8)">
          <v-card class="unvergessen-shadow">
            <div>
              <h3 style="text-align: center" class="mt-6">Bearbeitete Fälle der Letzten 14 Tage</h3>
              <div style="padding: 10px">
                <apex-chart
                  type="line"
                  height="350"
                  ref="realtimeChartAdmin"
                  :options="chartOptionsAdmin"
                  :series="seriesAdmin"
                ></apex-chart>
              </div>
            </div>
          </v-card>
        </div>
      </v-container>
    </div>

    <v-bottom-navigation class="secondary">
      <div style="text-align: center; padding-top: 15px">
        Fragen? Schreib uns im Google Chat oder ruf uns an: 0681 965 938 80
      </div>
    </v-bottom-navigation>
  </div>
</template>

<script>
import axios from 'axios'
import ApexChart from 'vue-apexcharts'

export default {
  data () {
    return {
      from: {},
      to: {},
      totalCases: 0,
      chartOptions: {},
      chartOptionsAdmin: {},
      series: [{
        name: 'cases',
        type: 'column',
        data: []
      }],
      seriesAdmin: [{
        type: 'line',
        data: []
      }]
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    isAdmin () {
      return this.$store.getters.admin
    }
  },
  created () {
    axios.get('/case/number')
      .then(res => {
        this.totalCases = res.data.cases
        if (this.user.accessRoles.cases.userStats) {
          var cases = []
          var dates = []
          for (var i = 0; i < res.data.statistics.length; i++) {
            cases[i] = res.data.statistics[i].cases
            dates[i] = new Date(res.data.statistics[i].date).getDate() + '.' + (new Date(res.data.statistics[i].date).getMonth() + 1)
          }
          this.$refs.realtimeChart.updateSeries([{
            data: cases
          }], false, true)
          this.$refs.realtimeChart.updateOptions({
            xaxis: {
              categories: dates
            }
          })
        }
        if (this.user.accessRoles.cases.adminStats) {
          var users = []
          var totalDIVs = res.data.adminStats.length
          var totalColors = totalDIVs
          var colors = []
          for (i = 0; i < res.data.adminStats.length; i++) {
            var casesAux = []
            var color = 'hsl( ' + this.selectColor(i, totalColors) + ', 100%, 50% )'
            for (var c = 0; c < res.data.adminStats[i].cases.length; c++) {
              casesAux[c] = res.data.adminStats[i].cases[c].cases
            }
            colors[i] = color
            users[i] = { name: res.data.adminStats[i].userName, data: casesAux }
          }
          this.$refs.realtimeChartAdmin.updateSeries(users, false, true)
          this.$refs.realtimeChartAdmin.updateOptions({
            colors: colors,
            xaxis: {
              categories: dates
            }
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    downloadFile () {
      axios.get(process.env.VUE_APP_API_URL + '/statistics/extractPagesPerId')
        .then(res => {
          const blob = new Blob(['\ufeff' + res.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'download.csv'
          link.click()
        })
    },
    downloadFileWithTime () {
      if (this.from !== '' && this.to !== '') {
        axios.get(process.env.VUE_APP_API_URL + '/statistics/extractPagesPerId', { params: { f: this.from + ':00.000Z', t: this.to + ':00.000Z' } })
          .then(res => {
            const blob = new Blob(['\ufeff' + res.data], { type: 'text/csv' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'download.csv'
            link.click()
          })
      }
    },
    startWork (type) {
      if (type === 'regular') {
        localStorage.setItem('uv_a_w', true)
        this.$router.push({ name: 'Work' })
      } else {
        localStorage.setItem('uv_a_w', true)
        this.$router.push({ name: 'Work', query: { e: true } })
      }
    },
    selectColor (colorNum, colors) {
      if (colors < 1) colors = 1 // defaults to one color - avoid divide by zero
      return colorNum * (360 / colors) % 360
    }
  },
  components: {
    apexChart: ApexChart
  }
}
</script>
